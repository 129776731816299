const fr = {
  home: `Accueil`,
  subtitle: `Tous les jours, on sélectionne 1 à 3 news en Economie/Finance qu’on vulgarise en 2 minutes de lecture`,
  what: {
    title: `Filite, c’est quoi?`,
    subtitle1: `FINANCE + ENLIGHT`,
    subtitle2: `Il existe 3 types de sujets: micro, macro et crypto`,
    macro: {
      emoji: `🌍`,
      title: `Macroéconomie:`,
      body: `Quel va être l'impact du cours du 💲 dollar sur celui du 🛢️ pétrole ?`,
    },
    micro: {
      emoji: `📈`,
      title: `Microéconomie:`,
      body: `Quel va être l'impact d'une annonce de dividende ou de résultat sur l'action 🇺🇸 Netflix ?`,
    },
    crypto: {
      emoji: `₿`,
      title: `Crypto:`,
      body: `"The Merge" de l'Ethereum, qu'est-ce que c'est et en quoi c'est une bonne chose ?`,
    },
    body: `Si ces titres te disent vaguement quelque chose et que tu veux les 
    comprendre en 2min quotidiennement, alors cette newsletter est faite pour toi !
`,
    subscribe: `Incris toi à la newsletter !`,
  },
  testimonials: {
    title: `Ils aiment Filite`,
    subtitle: `TÉMOIGNAGES`,
    subtitle2: `Ces mots nous aident à nous améliorer et nous motivent pour continuer :)`,
  },
}

export default fr
