import Iconify from "../../components/Iconify"
// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
}

const menuConfig = [
  {
    title: "home",
    icon: <Iconify icon={"eva:home-fill"} {...ICON_SIZE} />,
    path: "/",
  },
]

/*
  {
    title: "Blog",
    icon: <Iconify icon={"eva:book-open-fill"} {...ICON_SIZE} />,
    path: PATH_PAGE.blog,
  },
 */
export default menuConfig
