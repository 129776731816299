import { NavLink as RouterLink, NavLinkProps } from "react-router-dom"
// @mui
import { styled } from "@mui/material/styles"
import { Link, Stack, LinkProps } from "@mui/material"
// components
import Iconify from "../../components/Iconify"
//
import { MenuProps, MenuItemProps } from "./type"
import useLocales from "../../hooks/useLocales"

// ----------------------------------------------------------------------

interface LinkStyleProps extends LinkProps {
  component?: React.ForwardRefExoticComponent<NavLinkProps & React.RefAttributes<HTMLAnchorElement>>
  to?: string
  end?: boolean
}

const LinkStyle = styled(Link)<LinkStyleProps>(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.primary,
  marginRight: theme.spacing(5),
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
  "&:hover": {
    opacity: 0.48,
    textDecoration: "none",
  },
}))

// ----------------------------------------------------------------------

export default function MenuDesktop({ isOffset, navConfig }: MenuProps) {
  return (
    <Stack direction="row">
      {navConfig.map((link) => (
        <MenuDesktopItem key={link.title} item={link} isOffset={isOffset} />
      ))}
    </Stack>
  )
}

// ----------------------------------------------------------------------

type MenuDesktopItemProps = {
  item: MenuItemProps
  isOffset: boolean
}

function MenuDesktopItem({ item, isOffset }: MenuDesktopItemProps) {
  const { title, path, children } = item

  const { translate } = useLocales()

  if (children) {
    return (
      <>
        <LinkStyle
          sx={{
            display: "flex",
            cursor: "pointer",
            alignItems: "center",
            ...(isOffset && { color: "text.primary" }),
          }}
        >
          {translate("home")}
          <Iconify icon={"eva:arrow-ios-downward-fill"} sx={{ ml: 0.5, width: 16, height: 16 }} />
        </LinkStyle>
      </>
    )
  }

  return (
    <LinkStyle
      to={path}
      component={RouterLink}
      end={path === "/"}
      sx={{
        ...(isOffset && { color: "text.primary" }),
        "&.active": {
          color: "primary.main",
        },
      }}
    >
      {translate(title)}
    </LinkStyle>
  )
}

// ----------------------------------------------------------------------

export type IconBulletProps = {
  type?: "subheader" | "item"
}
