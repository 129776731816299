import React from "react"
import ThemeProvider from "./theme"
import ScrollToTop from "./components/ScrollToTop"
import Router from "./routes"
import { MotionLazyContainer } from "./components/animate"

function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ScrollToTop />
        <Router />
      </ThemeProvider>
    </MotionLazyContainer>
  )
}

export default App
