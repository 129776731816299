import { Suspense, lazy, ElementType } from "react"
import { Navigate, useRoutes } from "react-router-dom"
import MainLayout from "../layouts/main"
import LogoOnlyLayout from "../layouts/LogoOnlyLayout"
import LoadingScreen from "../components/LoadingScreen"

// components

const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  )
}

export default function Router() {
  return useRoutes([
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "500", element: <Page500 /> },
        { path: "404", element: <Page404 /> },
        { path: "403", element: <Page403 /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: "blog", element: <ComingSoon /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ])
}

// MAIN
const HomePage = Loadable(lazy(() => import("../pages/Home")))
const ComingSoon = Loadable(lazy(() => import("../pages/ComingSoon")))

const Page500 = Loadable(lazy(() => import("../pages/Page500")))
const Page404 = Loadable(lazy(() => import("../pages/Page404")))
const Page403 = Loadable(lazy(() => import("../pages/Page403")))
