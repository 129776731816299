const en = {
  home: `Home`,
  subtitle: `Every day, we select 1 to 3 news in Eco/finance that we explain in 2 minutes of reading`,
  what: {
    title: `What is Filite?`,
    subtitle1: `FINANCE + ENLIGHT`,
    subtitle2: `There are 3 types of topics : Macro, Micro and Crypto`,
    macro: {
      emoji: `🌍`,
      title: `Macroeconomics:`,
      body: `What will be the impact of the 💲 dollar price on the 🛢️ oil price?`,
    },
    micro: {
      emoji: `📈`,
      title: `Microeconomics:`,
      body: `What is going to be the impact of a dividend or earnings announcement on 🇺🇸 Netflix stock?`,
    },
    crypto: {
      emoji: `₿`,
      title: `Crypto:`,
      body: `"The Merge" of Ethereum, what is it and how is it a good thing?`,
    },
    body: `If these headlines speak to you from near or far and you want to 
    understand them in 2 minutes daily, then this newsletter is for you!`,
    subscribe: `Subscribe to our newsletter`,
  },
  testimonials: {
    title: `They love Filite`,
    subtitle: `TESTIMONIALS`,
    subtitle2: `These words help us to improve and motivate us to continue :)`,
  },
}

export default en
